.media-wrapper {
  background: var(--table-wrapper);
  box-shadow: var(--box-shadow);
}

.green-growth {
  color: rgba(39, 174, 96, 1);
  background-color: rgba(52, 179, 106, 0.19);
  font-weight: 600;
}

.red-growth {
  color: rgba(232, 76, 59, 1);
  background-color: rgba(232, 76, 59, 0.16);
  font-weight: 600;
}

.white-growth {
  color: transparent;
  background-color: transparent;
}
.statitics-wrapper {
  /* background: transparent; */
  border: 1px solid var(--border-line1);
  border-radius: 10px;
}
.remove-btn {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  font-weight: 600;
}
.social-media-btn {
  background: var(--primary-color);
  color: var(--light-color);
  margin-left: 10px;
  font-weight: 600;
}
.anticon-info-circle svg {
  color: var(--primary-color) !important;
  margin-top: 3px;
}

.social-media-traffic-chart .apexcharts-radialbar-label {
  cursor: default !important;
}
