.reports-section .page-heading{
    padding: 16px 0;
}
.insight-page .page-heading button {
    display: none;
}

.insight-box {
    background-color: var(--white-color);
    /* padding: 16px; */
    margin: 0 16px;
    border-radius: 10px;
}

.insight-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
}

.insight-button-bar {
    width: 100%;
    height: 80px;
}

.insight-button {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
}
.insight-button button{
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    color: #1C2D37;
}
.btn-active {
    background: #2B9FFF!important;
    color: var(--white-color)!important;
}

.insight-card {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
    /* padding-bottom: 20px; */
}

.insight-card img {
    width: 100%;
    height: -webkit-fill-available;
    border-radius: 8px 0 0 8px;
}

.insight-big-card {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: #FFFFFF;
}

.card-heading {
    font-style: normal;
    font-weight: 600;
    padding-top: 8px;
    padding-bottom: 8px;
    /* padding-left: 16px; */
    font-size: 18px;
    line-height: 22px;
    color: #1C2D37;
}

.card-para {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 16px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.50);
    padding-top: 10px;
}

.card-date {
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: #000000;
}

.insight-card button {
    color: #2B9FFF;
}

.sidebar-heading {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 16px;
    color: #000000;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    background: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px 8px 0 0;
}

.sidebar-para {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-left: 16px;
    color: #000000;

}

.sidebar-para p {
    color: #1C2D37;
    font-size: 13;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.sidebar-para a {
    color: #004877;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.show-more {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-align: center;
    width: 75%;

}