@font-face { font-family: "Inter-Medium"; src: url(../assets/fonts/Inter-Medium.ttf);}
@font-face {font-family: "Inter-Regular";src: url(../assets/fonts/Inter-Regular.ttf);}

*{ margin: 0; padding: 0; box-sizing: border-box;}

/* body color */
body { background: var(--background-color);}


/* animations */

.animateThis {position:relative;will-change:opacity transform}
.slideLeft {opacity:0;transition: all 1s ease;transform: translate3d(50px, 0px, 0px)}
.slideLeft.in-view {opacity: 1;transform: translate3d(0px, 0px, 0px)}
.slideRight {opacity:0;transition: all 1s ease;transform: translate3d(-50px, 0px, 0px)}
.slideRight.in-view {opacity: 1;transform: translate3d(0px, 0px, 0px)}
.slideTop {opacity:0;transition: all 1s ease;transform: translate3d(0px, 50px, 0px)}
.slideTop.in-view {opacity: 1;transform: translate3d(0px, 0px, 0px)}
.fadeGrow {opacity:0;transition: all 1s ease;transform:scale(.5)}
.fadeGrow.in-view {opacity:1;transform:scale(1)}
.fadeIn {opacity:0;transition: all 1s ease}
.fadeIn.in-view {opacity:1}
.curtain {clip-path:polygon(50% 0, 50% 0, 50% 100%, 50% 100%);transition: all 1s ease;}
.curtain.in-view {clip-path:polygon(0 0, 100% 0, 100% 100%, 0 100%);}

/* MODAL */
.ant-modal .ant-modal-content {
  padding: 0;
  margin: 0;
  background-color: var(--table-wrapper);
}

.ant-modal-footer {
  margin-top: 0;
}

/* TABLE */
.ant-table-wrapper .ant-table {
  background: var(--table-background);
  border-radius: 0 0 10px 10px;
}

.ant-table-thead tr {border-radius: 0px !important;}

.ant-table-wrapper .ant-table-thead > tr > th {
  background: var(--table-background) !important;
  border-radius: 0px !important;
  color: var(--thead-text);
  text-transform: uppercase;
  transition: none !important;
}

.ant-table-tbody > tr {
  border-bottom: 1px solid #e9e7fd !important;
  border-radius: 0px;
  font-family: "Inter-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--tbody-text);
  height: 75px;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: none;
}
.ant-table-tbody > tr > td {
  border-top: 1px solid #e9e7fd !important;
}
.ant-table-tbody > tr:last-child > td {
  border-bottom: 1px solid #e9e7fd !important;
}

.ant-table-tbody > tr > td {
  text-transform: capitalize;
}

.ant-table-tbody>tr:last-child>td {
    border-bottom: 1px solid #E9E7FD !important;
}
.ant-table-wrapper .ant-table-thead > tr > td {
  transition: none !important;
}


.ant-table-cell::before {
  display: none;
}


/* ANTD SELECTOR */
.ant-select {
  width: 100%;
  border-radius: 8px;
}

.ant-select-selector {
  height: 48px !important;
  border-radius: 8px;
  border: 1px solid var(--border-line) !important;
  transition: none!important;
}

.ant-select-selection-item {
  padding: 10px 3px !important;
  transition: none!important;
}
.ant-input ,.ant-input-affix-wrapper{
  /* height: 48px !important; */
  border-radius: 8px;
  border: 1px solid var(--border-line) ;
  background-color: var(--overview-background-color);
  color: var(--dark-color);
  transition: none!important;
}
table > thead > tr:first-child > *:last-child {
  /* width: 10%; */
  border-bottom: none;
}

.ant-table-thead>tr>td {
    background: none !important;
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
    background: none !important;
}

/* DELETE MODAL */
.delete-modal-header h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;
  color: var(--dark-color);
  padding: 18px 16px 17px 16px;
  border-bottom: 1px solid var(--clr-divider);
}

.delete-modal-body {
  padding: 25px 25px 15px !important;
}

.delete-modal-body h6 {
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  color: var(--tbody-text);
}

.delete-btn {
  font-weight: 700;
  width: auto;
  height: 48px;
  background: #004877 !important;
  color: #fff !important;
  border-radius: 8px;
  margin: 0 !important;
  border-color: #004877 !important;
}

.checkbox-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1c2d37;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
}

.ant-checkbox .ant-checkbox-inner {
  box-sizing: border-box;
  position: relative;
  top: 0;
  inset-inline-start: 0;
  display: block;
  width: 18px;
  height: 18px;
  direction: ltr;
  border: 2px solid #004877;
  border-radius: 4px;
  border-collapse: separate;
  transition: all 0.3s;
}

/* PAGINATION */
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  display: none;
}

.ant-pagination .ant-pagination-options {
  display: none;
}

.client-pagination {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: var(--table-background) !important;
  border-radius: 0 0 7px 7px;
}

.client-pagination .ant-select-selector {
  height: 28px !important;
  padding: 0 1px !important;
  border: 1px solid #E9E7FD !important;
}

.client-pagination .ant-select-selection-item {
  color: var(--color-black);
  opacity: 1 !important;
  line-height: 8px !important;
}

.client-pagination .ant-select-selector .ant-select-selection-search {
  border-left: 1px solid #00000026 !important;
  inset-inline-start: 28px;
}

.client-pagination .ant-select-arrow {
  color: var(--color-black);
  inset-inline-end: 7px;
  margin-top: -5px;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0;
  display: none;
}

.report-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ant-pagination .ant-pagination-item a {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height */
  padding: 0;
  color: #8b909a;
  border-radius: 4.596px;
  background: var(--pagination-background);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-pagination-item-ellipsis{
  color: #8b909a!important;
}
.ant-pagination .ant-pagination-item-active a {
  height: 32px;
  width: 32px;
  color: var(--light-color) !important;
  font-weight: 900 !important;
  border-radius: 4px;
  background: var( --primary-color);
  /* box-shadow: 0px 2.297872304916382px 4.595744609832764px 0px
    rgba(165, 163, 174, 0.3); */
}

.ant-pagination .ant-pagination-item a .anticon svg {
  color: red;
}

.ant-pagination .ant-pagination-item-active:hover a {
  color: var(--white-color);
}

.ant-pagination .ant-pagination-item-active {
  border: none;
}

.ant-pagination .ant-pagination-prev {
  margin-inline-end: 0;
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  /* border-radius: 4px;
  background: var(--polstrat-primary);
  box-shadow: 0px 2.297872304916382px 4.595744609832764px 0px
    rgba(165, 163, 174, 0.3);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  background: var(--white-color);
  border-radius: 4px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center; */

  /* align-items: flex-start; */
  background: var(--pagination-background);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4.596px;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  justify-content: center;
  line-height: 1px;
  padding: 4.596px 9.191px;
  width: 32.17px;
  align-items: center;
  justify-content: center;


}

.ant-pagination-item-link {
  margin-right: 8px;
}

.ant-pagination .anticon {
  vertical-align: middle;
  color: var(--dark-color);
  opacity: 0.2;

}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.06);
  width: 24px;
  height: 24px;
  margin-bottom: 7px;
}

/* fliter */

.filter-container {
  background-color: var(--white-color);
  color: var(--color-black);
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px;
  width: 328px;
  /* height: 400px; */
  /* padding: 20px; */
  position: absolute;
  z-index: 100;
  right: 32px;
  margin-top: 12px;

  /* top: 209px; */
}

.Tabs .TabsBody .search-filter-container .filter-container {
  background-color: var(--white-color);
  color: var(--color-black);
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px;
  width: 328px;
  /* height: 400px; */
  position: absolute;
  z-index: 100;
  right: 230px;
  /* top: 56px; */
}

.filter-container-body {
  padding: 15px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
}

/* filter Container */

.filter-container p {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-black);
}

.filter-container .filter-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 20px;
}

.filter-container-body label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--dark-color);
}

.filter-container-body input {
  /* height: 30px; */
}

.filter-container-footer {
  padding: 15px 20px;
}

.filter-container-footer .upt-btn {
  background-color: var(--color-black);
  color: var(--white-color);
  font-weight: 600;
}

.filter-container-footer .cnl-btn {
  background-color: var(--white-color);
  border: none;
  color: var(--color-black);
  font-weight: 600;
}

.filter-container-body .ant-select-selector {
  height: 40px !important;
}

.filter-container-body .ant-select-selection-item {
  padding: 5px !important;
}

.custom-ok-modal-button {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  width: 120px;
  height: 48px;
  border: 1px solid rgba(0, 72, 119, 0.5) !important;
  border-radius: 8px;
  height: 40px;
  /* color: #121e27 !important; */
  background: var(--polstrat-primary) !important;
}
.custom-cancel-modal-button {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  width: 120px;
  height: 48px;
  border: 1px solid rgba(0, 72, 119, 0.5) !important;
  border-radius: 8px;
  height: 40px;
  color: #121e27 !important;
}

.lang-selector .ant-select-selector {
  line-height: 32px!important;
  height: 32px !important;
  border: 1px solid #3BBEE896 !important;
 }

 .lang-selector .ant-select-selection-item{
padding: 2px !important;
color: var(--dark-color);

 }

 .dropdown svg{
  cursor: pointer;
  display: inline-block;
  font-size: 22px;
  width: 34px;
  cursor: pointer;
  fill: var(--table-action-svg);
 }

 .dropdown .anticon {
  padding: 4px;
  border-radius: 6px;
  transform: rotate(90deg);
}

 .ant-select-dropdown {
  background: var(--input-bg);
  color: var(--tbody-text);
  border: 2px solid var(--table-border)!important;
}

.ant-select-selection-item, .ant-select-selection-placeholder {
  font-weight: 400;
  font-size: 14px;
  /* line-height: 42px !important; */
  margin-top: 1px;
  color: #fff;
}

.ant-select-arrow {
  color: var(--primary-color) !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  /* inset-inline-start: 0px; */
  inset-inline-end: 0px;
  bottom: 0;
}

.overview-container .ant-select-selector {
  border: 1px solid var(--overview-dropdown) !important;
  height: 32px!important;
  /* background: transparent!important; */
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: var(--overview-background-color);
  color: var(--dark-color);
  border: 1px solid #d9d9d9;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-dropdown .ant-select-item-option-content {
  color: var(--tbody-text);
}

/* .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: var(--tbody-text);
  background-color: var(--dropdown-selected);
} */

.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled){
  color: var(--tbody-text);
  /* background-color: var(--dropdown-selected); */
}

.ant-empty-description {
  color: #3bbee8;
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
}

.ant-checkbox+span {
  color: var(--dark-color);
}


.ant-select-dropdown .ant-select-item:hover{
  background: var( --table-row-hover) !important;
}

.ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell-row-hover {
  background: var( --table-row-hover) !important;
  border-radius: 0px !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  background-color: var(--search-input-selected);
}

.ant-divider-horizontal {
  margin: 14px 0;
}

.ant-divider-horizontal.ant-divider-with-text {
  font-size: 13px;
  margin: 10px 0;
}

.ant-input-number .ant-input-number-input, .ant-picker, .ant-upload-wrapper .ant-upload-drag .ant-upload-btn  {
  background-color: var(--overview-background-color);
  /* border: var(--border-line) !important; */
  color: var(--dark-color);
  border-radius: 8px;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: var(--input-bg);
  border-radius: 0;
  border-width: 0;
  color: var(--dark-color);
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-text{
  color: var(--dark-color);

}


.ant-select-selection-item,
.ant-select-selection-placeholder {
  opacity: 0.9 !important;
  font-size: 14px;
  color: var(--input-placeholder) !important;
}

input::placeholder {
  opacity: 0.9 !important;
  color: var(--input-placeholder) !important;
  font-size: 14px;
}

.ant-picker-dropdown .ant-picker-content th, .ant-picker-dropdown .ant-picker-cell-in-view {
color: var(--date-picker);
}
.modal-body-container{
  overflow-y: scroll;
  height: 600px;
  margin-right: 2px;
}
.ant-modal-root .ant-modal-mask {
  background: var(--modal-opacity);
}
.ant-input-number,.ant-picker{
  border: 1px solid var(--border-line)!important;
  border-radius: 8px;
  color: var(--dark-color)!important;
}
.ant-input-group .ant-input-group-addon {
  border: 1px solid var(--border-line);
  background-color: var(--overview-background-color);
  color: var(--dark-color);
}
.ant-input-group .ant-input-group-addon {
  border-right: 1px solid var(--clr-divider) !important;
}
.ant-picker .ant-picker-suffix,.ant-picker .ant-picker-input >input,.ant-input-suffix svg {
  color: var(--input-label);
}
.ant-input[disabled], .ant-select-disabled .ant-select-selector {
  background-color: var(--input-disabled) !important;
  color: #8d969b;
  border: 1px solid var(--border-line);
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.ant-input::placeholder,.ant-input-number-input::placeholder,
.ant-select-selection-placeholder,.ant-picker-input >input::placeholder,.signup-page input::placeholder {
  opacity: 0.25!important;
}
.ant-select-selector .ant-select-selection-item,
.ant-input,.ant-input-number-input,
.ant-select-selection,.ant-picker-input >input {
  opacity: 1!important;
}
.dropdown {
  position: relative !important;
}
.dropdown-content div:hover,
.ant-table-row-selected > td {
  background: var(--table-row-hover) !important;
  border-radius: 8px;
}
.dropdown-content a {
  color: inherit;
}

.ant-input-affix-wrapper >input.ant-input,
.ant-input-textarea-show-count >.ant-input {
  background-color: var(--input-bg);
}
.ant-table-column-sorter-inner .anticon {
  color: var(--thead-text) !important;
}
.ant-table-column-sorter-inner .active {
  color: var(--primary-color) !important;
}
.ant-table-column-sorters {
  display: inline-flex !important;
  align-items: flex-start !important;
}
input[type] {
  caret-color: var(--dark-color)!important;
}
input:focus-visible {
  outline-offset: 0px!important;
  outline: none!important;
}

.ant-form-item{
  margin-bottom: 16px;
}
.modal-container .ant-modal-content {
  margin-top: -60px;
}