.reports-section {
    border-radius: 10px;
    padding: 15px;
}

.reports-info-modal .reports-modal-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.reports-info-modal .reports-modal-header h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: var(--tbody-text);
    padding: 16px;
}

.reports-info-modal .reports-modal-body {
    padding: 24px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background: var(--background-color);
}

.request-report {
    border-radius: 10px;
    background: var(--table-wrapper);
    padding: 20px;
}

.reports-info-modal .reports-modal-body h5 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 24px;
    color: var(--dark-color);
}

.reports-modal-row label {
    display: block;
    padding-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
    color: var(--input-label);
}

.reports-modal-row input {
    height: 48px;
    background: var(--white-color);
    border: none !important;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}

.reports-modal-row textarea {
    background: var(--white-color);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}

.req-callback {
    display: flex !important;
    opacity: 1 !important;
}

.reports-modal-row .ant-select {
    width: 271px !important;
    margin-bottom: 40px;
}

.reports-modal-row .ant-checkbox .ant-checkbox-inner {
    border: 2px solid #2B9FFF;
}

.reports-modal-row .ant-select-selector .ant-select-selection-placeholder {
    padding: 8px;
    line-height: 30px;
    transition: all 0.3s;
}

.reports-modal-row .ant-input-affix-wrapper .ant-input-show-count-suffix {
    color: rgba(0, 0, 0, .45);
    position: absolute;
    right: 0;
    top: 57px;
}

.reports-modal-wrapper .modal-footer-button {
    padding: 10px 14px;
    margin: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: var(--white-color);
    background: var(--polstrat-primary);
    border-radius: 8px;
    padding: 15px 20px;
}


.ant-dropdown .ant-dropdown-menu {
    background: var(--white-color);
    border: 1px solid rgba(15, 4, 4, 0.15);
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.report-dropdown {
    display: inline-flex !important;
    margin: 0px -10px;
}

.ant-dropdown-menu-title-content svg {
    width: 28px;
    height: 18px;
    margin: 2px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon {
    display: flex;
    flex-direction: row;
    height: 14px;
    width: 12px;
    margin-right: 10px;
}

.reports-modal-body .share-record {
    display: flex;
    flex-direction: row;
    width: 568px;
    height: 80px;
    padding: 20px 30px;
    background: #F2F2F2;
    border-radius: 8px;
}

.share-record svg {
    width: 40px;
    height: 40px;
}

.share-record p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1C2D37;
    margin-left: 20px;
}

.reports-modal-body h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #001D30;
    margin-top: 18px;
}

.share-modal-row label {
    display: block;
    padding-bottom: 8px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}


.share-modal-row .tags span {
    display: inline-block;
    border-radius: 25px;
    border: 1px solid #CCDAE4;
    background: var(--white-color);
    padding: 8px 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: 20px 10px 24px 0;
}

.chief-of-staff {
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #1C2D37;
    opacity: 0.6;
}

.share-modal-row .ant-select {
    width: 271px !important;
}

.share-modal-row .ant-select-selection-item {
    line-height: 10px !important;
    font-size: 14px;
}



.reports-table-wrapper .ant-table-thead>tr>td {
    /* background: var(--white-color) !important; */
    border-bottom: none !important;
}


.report-data-modal .report-type span {
    padding: 7px 16px;
    border-radius: 20px;
    border-width: 0.5px;
    border-style: solid;
    /* margin-left: 16px; */
    font-size: 13.353px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.report-data-modal .dropdown-content {
    height: auto;
    position: absolute;
    z-index: 99;
    width: max-content;
    background-color: var(--light-color);
    margin-right: 30% !important;
    padding: 14px;
    border: 1px solid var(--border-line);
    border-radius: 8px 0 8px 8px;
    border-top: 0 solid #cddee9;
    box-shadow: 1px 3px 10px #00487733;
    border-radius: 8px 0px 8px 8px;
}

.report-data-modal .dropdown-content div {
    cursor: pointer;
    text-align: left;
    padding-bottom: 4px;
}

.report-data-modal .ant-btn {
    background: #222222;
    width: auto;
    padding: 0 10px;
}

.report-data-modal .ant-btn:hover {
    background: #222222;
}

.report-data-modal .report-action .anticon {
    color: var(--polstrat-primary);
    padding: 4px;
    border-radius: 6px;
    transform: rotate(90deg);
}

.report-data-modal .report-action .anticon svg {
    display: inline-block;
    font-size: 22px;
    width: 34px;
    cursor: pointer;
    fill: var(--action-svg-fill);
}

.report-data-modal .ant-table-wrapper td.ant-table-column-sort,
.report-table-wrapper .ant-table-wrapper td.ant-table-column-sort {
    /* background-color: var(--white-color); */
    background-color: transparent;
}

.report-data-modal .anticon-caret-up svg,
.report-data-modal .anticon-caret-down svg {
    font-size: 12px !important;
}

/* .report-data-modal .ant-table-column-sorter-inner {
    margin-left: -36px;
} */

.report-data-modal .dropdown-content {
    padding: 0 !important;
    border-radius: 10px;
}

.report-data-modal .dropdown-content div {
    padding: 10px 40px 10px 10px;
}

/* .dropdown-content div:hover {
    background: rgba(59, 190, 232, 0.10);
    color: #000;
} */

.searchWrapper {
    min-height: 48px !important;
}

.searchWrapper,
.optionListContainer {
    width: 70% !important;
    border: 1px solid #CDDEE9 !important;
}


.optionListContainer ul li {
    background: transparent !important;
    color: #1C2D37 !important;
}

.optionListContainer ul li:hover,
.optionListContainer ul .selected {
    background: rgba(59, 190, 232, 0.10) !important;
    color: var(--polstrat-primary) !important;
}

.multiselect-container .checkbox {
    background-color: red !important;
    border-color: red !important;
}

.multiselect-container .checkbox:checked {
    background-color: green !important;
    border-color: green !important;
}

.multiselect-container .checkbox:focus {
    outline-color: blue !important;
}

.multiselect-container .checkbox::before {
    border-color: red !important;
}

.reports-table-wrapper .search-filter .filter-option {
    display: none;
}

.report-filter .search-filter .search-option input {
    margin-top: 1px !important;
}

.report-filter .search-option img {
    margin-top: 2px;
}

.report-filter .ant-select .ant-select-arrow {
    opacity: 1;
}

.report-filter .anticon-down {
    color: var(--polstrat-primary);
    opacity: 1;
}

.report-filter .ant-select-selector {
    height: 40px !important;
    width: 190px !important;
}

.report-filter .ant-select-selector .ant-select-selection-placeholder {
    padding-top: 3px;
}

.report-filter .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 18px;
    /* color: #1C2D37; */
    color: var(--tbody-text);
    font-size: 16px;
    font-weight: 400;
    opacity: 1;
}

.report-filter .ant-select-selector {
    border: 1px solid #ccdae4 !important;
}

.report-filter-select span {
    color: #1C2D37;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.65;
}

.report-data-modal .ant-table-column-sorters {
    display: inline-flex !important;
    align-items: flex-start !important;
}

.reports-table-wrapper .ant-table-column-sorters {
    display: inline-flex !important;
    align-items: flex-start !important;
}

.report-requested button {
    border: 1px solid #BBBBBB;
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.user-table-tabs .active {
    font-weight: 600 !important;
}

.report-action .dropdown {
    position: relative !important;
}