.priority-stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-radius: 10px; */
  /* background: var(--table-background); */
  box-shadow: var(--gms-stats-bg-shadow);
  height: 88px;
  border-radius: 32px;
  box-shadow: var(--box-shadow);
}

.priority-stats p {
  color: var(--thead-text);
  font-family: "Inter-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.387px;
  margin-top: 10px;
}

.priority-stats div {
  /* color: rgba(0, 0, 0, 0.5); */
  color: var(--stat-text);
  font-family: "Inter-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.387px;
  margin-top: 10px;
}

.priority-stats h6 {
  color: var(--tbody-text);
  font-family: "Inter-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.387px;
}

.priority-stats img {
  height: 47px;
  width: 47px;
}

.status-stats {
  border-radius: 32px;
  background: var(--table-wrapper);
  box-shadow: var(--box-shadow);
}

.status-stats h6 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.status-stats p {
  margin-top: 10px;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.status-item1 {
  color: #59748a;
}

.status-item2 {
  color: var(--dark-color);
}

.status-item3 {
  color: #e67e22;
}

.status-item4 {
  color: #2b9fff;
}

.status-item5 {
  color: #27ae60;
}

.gms-modal {
  border-radius: 10px !important;
  /* background: #f2f8fd; */
  background: var(--background-color);
}

.modal-body {
  background-color: var(--table-wrapper);
  border-radius: 10px;
  margin: 20px;
}

.details-form-wrapper {
  height: 75vh;
  overflow-y: scroll;
  background: var(--table-wrapper);
  padding: 20px;
  border-radius: 0 0 10px 10px;
}

.details-form-wrapper::-webkit-scrollbar {
  width: 8px;
}

.details-form-wrapper::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 10px;
}

.details-form-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #7a7a7a;
}

.gms-page-section .tms-tabs {
  width: fit-content;
}

.gms-page-section .tms-tabs button:nth-child(2) {
  width: auto;
}

.gms-page-section .gms-page .tabs .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 0px;
}

.gms-page-section .gms-page .tabs .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
  box-sizing: border-box;
  height: 40px;
  /* background: #ffffff; */
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex: unset;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}

.gms-page-section .gms-page .tabs .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 105px;
  height: 40px;
  background: rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  color: #000 !important;
}

.gms-page-section .gms-page .tabs .ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #1677ff;
  pointer-events: none;
  display: none;
}

.gms-page-section .gms-page .tabs .ant-tabs .ant-tabs-tab-btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 105px;
  height: 40px;
  background: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  color: #000 !important;
}

.gms-page-section .gms-page .tabs .ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  pointer-events: none;
}

.gms-page-section .gms-page .tabs .ant-tabs>.ant-tabs-nav {
  margin-left: 10px;
  margin-bottom: 20px;
}

.gms-page-section .gms-page .tabs .ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: none;
}

.gms-page-section .gms-page .tabs {
  margin: 15px;
}

.gms-page-section .gms-page .tabs .ant-tabs {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 20px 0px 0;
  margin: 30px 0px 0;
}

.search-filter-container {
  position: absolute;
  right: 0px;
  top: -76px;
}

.gms-map-page img {
  mix-blend-mode: luminosity;
  filter: grayscale(100%);
}

.gms-map-page .search-filter .filter-option {
  display: none;
}

.btn-flex,
.btn-space,
.btn-flex1 {
  background-color: #fff;
}

.modal-header {
  background-color: var(--table-wrapper);
}

.btn-flex,
.btn-space,
.btn-flex1 {
  padding: 20px 15px;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid var(--border-line, #ccdae4);
  background: var(--table-wrapper);
}

.btn-flex {
  background: var(--table-wrapper);
}

.gms-modal .modal-header {
  border-bottom: 1px solid var(--border-line);
  border-radius: 8px 8px 0px 0px;
  padding: 20px 15px;
  background: var(--table-wrapper);
}

.ant-modal-close-icon {
  color: var(--modal-close-icon) !important;
}

.modal-header p {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: var(--dark-color);
}

.gms-modal .modal-body h3 {
  margin: 20px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--modal-h3);
}

.gms-modal .modal-body {
  padding-bottom: 50px;
  padding: 20px;
}

.gms-modal .modal-body h3 {
  margin-top: 10px;
}

.gms-modal .modal-body .row,
.details-form-wrapper .row {
  margin-bottom: 30px;
  display: flex;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px !important;
  line-height: 48px;
}

.gms-modal input,
.details-form-wrapper input {
  height: 48px !important;
}

.primary-btn {
  width: auto;
  padding: 10px 30px;
  height: 48px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 8px;
  background: #004877;
  box-shadow: 0px 4px 10px 0px rgba(0, 72, 119, 0.3);
}

.btn-space {
  display: flex;
  justify-content: space-between;
}

.ant-modal .ant-modal-footer {
  padding-bottom: 14px;
}

.gms-modal .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--dark-color);
  background-color: #000;
}

.gms-modal label {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: var(--input-label);
}

.gms-page .stats-content .float-container {
  background: #d9d9d9;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  padding: 2px 8px;
}

.gms-page .page-heading {
  padding: 16px 5px;
}

.gms-page .float-child-arrow .change-icon {
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
  color: var(--color-black);
  font-size: 20px;
  font-weight: 400;
}

.gms-page .ant-table-tbody>tr>td:first-child {
  width: 15% !important;
}

.delete-btn:hover {
  color: #fff !important;
}

.ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-item {
  padding: 10px 0px;
  height: 48px;
  line-height: 32px;
}

.ant-input-group-addon {
  height: 48px !important;
  margin: 0px !important;
}

.ant-input-group>.ant-input:last-child {
  background: "#F2F2F2 !important";
}

.gms-select .ant-select-selector {
  height: 32px !important;
}

.gms-select .ant-select-selection-item {
  padding: 1px !important;
}

.g-body label {
  font-size: 17px;
  font-weight: 600;
}

.g-body p {
  font-size: 17px;
  color: #504f4f;
  font-weight: 400;
}

.gms-page .ant-space-align-center {
  align-items: center;
  justify-content: center;
}

.gms-page-tabs .overview-top {
  margin: 15px;
}

.gms-modal .modal-body {
  padding-bottom: 50px;
}

.prev-button {
  width: 120px;
  height: 48px;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
}

.prev-button:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.btn-flex {
  display: flex;
  justify-content: flex-end;
}

.btn-flex1 {
  display: flex;
  justify-content: space-between;
}

.ant-upload-wrapper .ant-upload-drag {
  height: 190px;
  width: 97%;
  background-color: #ffffff;
}

.gms-img-upload .ImgUpload {
  border: 1px solid black;
  width: 100px;
  padding: 8px 12px;
  margin: auto;
  font-weight: 600;
  font-size: 14.2452px;
  line-height: 17px;
  color: var(--primary-color)!important;
  border: 0.890323px solid var(--primary-color);
  border-radius: 7.12258px;
  margin-top: 20px;
}

.custom-img-upload svg {
  margin: auto;
  color: var(--primary-color) !important;
}

.custom-img-upload span {
  color: var(--stat-text);
}

.issue-details p span:first-child,
.gms-container p span:first-child {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--tbody-text);
  opacity: 0.65;
  display: inline-block;
  width: 25%;
}

.issue-details span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--dark-color);
}
.issue-details  button:nth-child(2) span {
  color: var(--stat-text);
}
.new-field label {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1c2d37;
  opacity: 0.65;
}

.gms-selection .ant-select {
  display: none;
}

.issue-details p {
  margin-bottom: 13px;
}

.issue-details h2 {
  margin-bottom: 15px;
  color: var(--primary-color)!important;
  font-family: "Inter-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.gms-page-section .client-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 0px !important;
  padding: 15px;
  position: absolute;
  width: -webkit-fill-available;
  background: #fff;
  margin-right: 15px;
}

.gms-page-section .pagination-text {
  color: #8b909a;
  font-family: "Inter-Regular";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.494px;
}

.ant-pagination .ant-pagination-item a,
.ant-pagination .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-radius: 4.596px !important;
}

.tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6.941px 6.941px 0px 0px;
  border-bottom: 1.157px solid #dbdade;
  padding-top: 1px;
}

.filter-button {
  margin-right: 20px;
  transition: none;
}

.breadcrumbs {
  border-radius: 8px;
  border: 1px solid #2b9fff;
  margin: 10px 0px 15px;
  padding: 10px 15px;
}

.breadcrumbs p {
  color: #667085;
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  display: flex;
}

.breadcrumbs span {
  color: var(--primary-color);
  font-weight: 600;
  line-height: 150%;
}

.assign-details-wrapper {
  margin: 20px;
}

/* .details-heading {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #ccdae4;
  background: var(--white-color);
  align-items: center;
  padding: 15px;
  border-radius: 10px 10px 0 0;
} */

.details-heading h4 {
  color: var(--input-label);
  font-family: "Inter-Bold";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dropdown-content div {
  cursor: pointer;
  text-align: left;
  padding: 5px;
  border-radius: 4px;
}



.priority-wrapper {
  border-radius: 20px;
  padding: 1px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  border: 0.5px solid var(--input-color);
}

.priority-wrapper span {
  font-size: 13.353px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.priority-wrapper img {
  height: 20px;
  margin-top: 4px;
}

.assign-form label {
  color: var(--tbody-text);
  font-family: "Inter-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6499999761581421;
}

.info-window-content {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.2);
  max-width: 250px;
  min-height: 200px;
  text-align: left;
}

.details {
  flex-grow: 1;
}

.info-window-content img {
  max-width: 100%;
}

.info-window-content h5 {
  font-size: 12px;
  margin: 7px 0;
}

.button-link {
  display: inline-block;
  margin-left: 80px;
  margin-top: 10px;
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  align-self: flex-end;
}

.button-link:hover {
  background: #0056b3;
}

.font-bold {
  font-weight: bold;
}

.flex-container {
  display: flex;
  align-items: center;
}

.cdn-icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}

.pinIcon img {
  width: 8.889px;
  height: 20px;
  flex-shrink: 0;
  filter: var(--assigned-status);
}

.priorityIcon img {
  width: 16px !important;
  height: 16px;
  flex-shrink: 0;
  margin-right: 10px;
}

.gms-table .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr>td:nth-child(2) {
  font-weight: 600;
}

.map-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100vh;
  margin: 0 auto;
  padding: 15px;
  border-radius: 8px;
  background: rgba(59, 190, 232, 0.06);
}

.loader {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.map-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100vh;
  margin: 0 auto;
}

.gms-uploaded-images-div {
  display: flex;
  flex-wrap: wrap;
  max-width: 75%;
}

.image-box {
  margin: 10px;
}

.uploaded-image_item {
  width: 100px;
  height: 100px;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.uploaded-image_item:hover {
  border: 2px solid #007bff;
}

.gms-modal .modal-body .ant-row {
  margin-bottom: 1px;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.delete-icon i {
  background-image: url("/src/assets/images/icon/delete.png");
  background-size: cover;
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: transparent;
}

.delete-icon:hover {
  background: rgba(255, 0, 0, 0.8);
  color: white;
}

.bg-active-card {
  background-color: #9fd9ff;
}

.ant-popover .ant-popover-inner {
  background-color: var(--table-wrapper);
  /* box-shadow: 0px 8px 16px 0px var(--modal-opacity); */
  border: 2px solid var(--table-border);
}

.gms-table .ant-picker ,.gms-table .ant-select-selector{
  border: 1px solid #ccdae4 !important;
}
.ant-divider-horizontal.ant-divider-with-text{
  border-block-color: var(--stat-text);
}
.ant-divider-inner-text{
  color:var(--dark-color)
}


.gms-modal .modal-body-container::-webkit-scrollbar {
  width: 8px;
}

.gms-modal .modal-body-container::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 10px;
}

.gms-modal .modal-body-container::-webkit-scrollbar-thumb {
  background:gray;
  border-radius: 10px;
}

.gms-modal .modal-body-container::-webkit-scrollbar-thumb:hover {
  background:#80888d;
}

.date-assign{
  width:fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccdae4 !important;
  border-radius: 8px;
  font-size: 14px;
  padding: 5px 10px;
}
.date-assign svg{
  font-size: 12px;
  color: var(--stat-text);
}
.completed-date{
border: none!important;
}