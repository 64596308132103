/* Client-Details */
.client-details p {
  display: flex;
  text-align: justify;
  width: 50%;
}

.client-details .details-value {
  margin-left: 50px;
  text-align: justify;
}

.client-details {
  background-color: var(--wrapper-bg);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 20px 20px;
}

.client-details .firstname-field p span:first-child {
  color: var(--tbody-text);
  opacity: 0.65;
  margin-right: 10px;
  font-weight: 400;
}
.client-details .firstname-field p span:last-child {
  color: var(--tbody-text);
  margin-left: 10px;
  font-weight: 400;
}

.tab-heading {
  border-bottom: 1px solid #e9e7fd;
  padding: 10px 0px;
  color: var(--primary-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* margin-bottom: 15px;    */
}
.download-button {
  background: var(--polstrat-primary) !important;
  color: var(--white-color);
}
.download-button {
  background: var(--polstrat-primary) !important;
  color: var(--white-color);
}
.download-Btn {
  display: flex;
  justify-content: flex-end;
}

.download-Btn button {
  height: 36px;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
