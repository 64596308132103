.office-table-inner .ant-table-thead th:nth-child(3) {
    width: 16%
}

.office-table-inner table>thead>tr:first-child>*:last-child {
    width: 10%;
    /* background: var(--white-color) !important; */
}

.office-table-inner .ant-table-tbody>tr>td:first-child {
    /* color: #1C2D37; */
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.office-table-inner .anticon,
.user-table-inner .anticon {
    /* color: #004877; */
    padding: 4px;
    border-radius: 6px;
    transform: rotate(90deg)
}

.office-table-inner .anticon svg {
    display: inline-block;
    font-size: 22px;
    width: 34px;
    cursor: pointer;
    fill : var(--table-action-svg);
}

.office-table-inner .ant-space,
.user-table-inner .ant-space {
    background: none;
}

/* VIEW OFFICCES */
.view-office-modal .ant-modal-body {
    padding-bottom: 5px;
    border-radius: 10px;
    background: var(--background-color);
    box-shadow: 1px 5px 10px 0px rgba(1, 29, 47, 0.25);
}

.view-office-modal .modal-header-office {
    border-bottom: 1px solid #CCDAE4;
    border-radius: 8px 8px 0px 0px;
    background: var(--white-color);

}

.view-office-modal .modal-header-office h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: #001D30;
    text-transform: capitalize;
}

.office-stat {
    border-radius: 10px;
    background: var(--table-wrapper);
    margin: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 30px 0 40px;
}

.office-stat div {
    width: 180px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    margin-right: 16px;
}

.office-stat div:last-child {
    width: 90px;
}

.office-stat div p {
    color: var(--office-stats-text);
    font-size: 17.47px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.office-stat div p:last-child {
    font-weight: 500;
}

.office-user-info {
    border-radius: 10px;
    /* background: var(--white-color); */
    margin: 20px;
}
.office-user-info .ant-table-wrapper .ant-table{
    padding-bottom: 20px;
    border-radius: 10px;
}
.office-user-info .ant-table-body::-webkit-scrollbar {
    width: 8px;
  }
  
  .office-user-info .ant-table-body::-webkit-scrollbar-track {
    border-radius: 10px;
    width: 10px;
  }
  
  .office-user-info .ant-table-body::-webkit-scrollbar-thumb {
    background:#b3c8d6;
    border-radius: 10px;
  }
  
  .office-user-info .ant-table-body::-webkit-scrollbar-thumb:hover {
    background:#b3c8d6;
  }
  .office-user-info .ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
}

.office-user-info .ant-table-thead th:first-child,
.office-user-info .ant-table-tbody>tr>td:first-child {
    padding-left: 4%;
    width: 33%;
}

.tab {
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.active-tabs {
    background-color: #007bff;
    color: var(--white-color);
}

.view-office-modal .ant-table-tbody tr {
    height: 40px;
    background: none;
    border: none !important;
}

.view-office-modal .ant-table-tbody>tr>td {
    border-top: none !important;
    border-bottom: transparent !important;
    text-transform: capitalize;
    color: var(--color-black);
    font-size: 16px;
    font-style: normal;
    line-height: normal;
}

.modal-body-office p:first-child {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #001D30;
}

/* .office-table-inner .dropdown-content,
.user-table-inner .dropdown-content {
    height: auto;
    position: absolute;
    z-index: 99;
    width: max-content;
    margin-left: -15%;
    padding: 10px;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #CDDEE9;
    box-shadow: 1px 3px 10px rgba(0, 72, 119, 0.2);
    border-radius: 10px;
}

.user-table-inner .dropdown-content {
    margin-left: -80%;
} */

.user-table-inner .contact-info .dropdown-content {
    /* margin-right: -45% !important; */
    left: 50%;
    top: 20%;
    transform: translate(-50%, 20%);
}

.user-table-inner .contact-info .dropdown-content div {
    text-transform: lowercase;
}

.office-table-inner .dropdown-content div,
.user-table-inner .dropdown-content div {
    cursor: pointer;
    text-align: left;
    padding-bottom: 9px;
}

.pagination-text {
    color: #8B909A;
    font-family: "Inter-Regular";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.494px;
}