@import './Styles/App.css';
@import './Styles/_color_variables.css';
@import './Styles/common.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

.places-container {
  background-color: white;
  position: absolute;
  /* top: 10px; */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 300px;
}

.map-container {
  width: 100%;
  height: 100vh;
}

.required-field {
  color: red;
  margin-left: 4px;
}
