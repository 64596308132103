.custom-tabble{
    /* border: 1px solid #CCDAE4; */
    border-radius: 8px;
    background-color: var(--white-color);
}

.customer-button {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    /* border: 1px solid rgba(0, 0, 0, 0.15); */
    /* border-radius: 25px; */
    color: var(--color-black);
    margin: 20px 7px 0px 7px;
    padding: 10px 16px 0px;
}

.customer-support-header{
    border-bottom: 1.157px solid var(--light-solid-color-extra-divider, #DBDADE);

}

.customer-support-box{
    height: 60px;
}

.customer-support-box .active{
    color: #004877;
    font-family: Inter;
    font-size: 17.354px;
    font-style: normal;
    font-weight: 600;
    line-height: 25.452px; /* 146.667% */
    border-bottom: 2.314px solid #004877;
    padding-bottom: 4px;
}

/* .custom-tabble .search-filter .filter-option{
    display: none;
} */

/* .cadre-modal-row textarea {
    height: 104px;
    background: var(--white-color);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 98%;
} */


.cust-table .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr>td:nth-child(2) {
    font-weight: 600;
}


.cust-supprt-section .cust-supprt-tabs {
    background: var(--table-wrapper);
    border-radius: 12.063px;
    /* box-shadow: 0 4.82539701461792px 8.44444465637207px 0 rgba(59,132,172,.2); */
    box-shadow: var(--box-shadow);
    cursor: pointer;
    padding: 28px;
}

.customer-support-stats {
    margin: 20px 0px;
  }
  
  .customer-support-stats .cust-suppr-btn {
    padding-left: 14%;
    padding-right: 14%;
  }
  
  .cust-supprt-section .cust-img-wrapper {
    width: 71px;
    height: 71px;
    flex-shrink: 0;
    border-radius: 57.404px;
    /* background: #3BBEE8; */
    box-shadow: 0px 4px 7px 0px rgba(59, 190, 232, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
  }
  
  .cust-supprt-section .cust-img-wrapper img {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  
  .cust-supprt-tabs p {
    color: var(--tbody-text);
    font-family: "Inter";
    font-size: 16.889px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.467px;
    opacity: 0.5;
  }
  
  .cust-supprt-tabs h2 {
    color: var(--tbody-text);
    font-family: "Inter";
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.467px;
  }