@font-face {
  font-family: "Inter-Medium";
  src: url(../assets/fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: "Inter-Regular";
  src: url(../assets/fonts/Inter-Regular.ttf);
}
::-webkit-scrollbar{width:6px;}
::-webkit-scrollbar-thumb{background-color:gray;border-radius:5px}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Inter-Regular";
}

.container {
  display: flex;
  /* margin-top: 80px; */
  width: 70px;
}

main {
  width: 100%;
  padding: 20px;
}

.sidebar {
  color: #fff;
  height: 100vh;
  /* overflow-y: auto; */
  position: fixed;
  top: 0;
  left: 0;
  /* border-radius: 10px; */
  /* background: var(--sidebar-bg); */
  background: linear-gradient(180deg, #095689 0%, #0c3b59 34.90%, #07263c 66.15%), url('../assets/images/sidebarImg.png');;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
  z-index: 11;
  transition: all 0.4s;
  box-sizing: border-box;
}



.sidebar-content {
  height: 99vh;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 2px;
}

/* Custom Scrollbar Styles */
.sidebar-content::-webkit-scrollbar {
  width: 8px;
}

.sidebar-content::-webkit-scrollbar-track {
  /* background: #d7e2ea;  */
  border-radius: 10px;
  width: 10px;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: #B3C8D6;
  border-radius: 10px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
  background: #B3C8D6;
}




/* Sidebar width 284px */
.sidebar .sidebarLeft {
  display: flex;
  align-items: center;
  /* background-color: #fff; */
  /* padding-top: 14px !important; */
}

/* .sidebarLeft:nth-child(3) .icon {
  margin: auto 5px !important;
} */


.sidebarLeft .logo {
  padding: 0;
}

.sidebarToggle {
  margin: auto 24px !important;
  width: auto !important;
}


.sidebar .navLeft {
  height: 79px;
  padding-top: 30px;
  margin-bottom: 30px;
}

.sidebar .menubar {
  cursor: pointer;
  display: block;
  margin: auto;
  width: auto;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.link {
  display: flex;
  transition: all 0.5s;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: "Inter-Regular";
  height: 55px;
  transition: color .15s ease-in;
}



.link:focus {
  outline: none !important;
}

.sidebar .active {
  position: relative;
}

.sidebar .active::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.20);
  border-left: 3px solid var(--white-color);
}

.sidebarLeft .icon {
  margin: auto 25px !important;
}
/* .sidebarLeft:nth-child(3) .icon {
  margin: auto 10px auto 5px !important;
} */
.link:hover {
  background: rgba(255, 255, 255, 0.20);
}

.sidebar .icon {
  display: block;
  margin: auto;
  width: auto;
  height: auto;
}


/* .link:nth-child(5) .icon img,
.link:nth-child(6) .icon img,
.link:nth-child(12) .icon img,
.link:nth-child(13) .icon img,
.link:nth-child(14) .icon img {
  width: 27px;
  height: 27px;
} */

/* .link:nth-child(10) .icon img {
  height: 20px;
} */




/* .sidebar .active.link:nth-child(10) .icon img {
  padding-left: 0 !important;
} */

/* .link:nth-child(11) {
  padding: 5px;
} */
.link:nth-child(10) {
  margin-top: 80px;
}

.link_text {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  padding: 17px 0;
  color: #FFF !important;
}

.nav {
  color: var(--color-black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 0 0 20px;
  height: 80px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}


/* Navbar scrolling background */
.nav-bg-color {
  background-color: var(--overview-nav-bg);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 1px 1px 3px rgba(0, 72, 119, 0.15);
  transition: all 0.3s;
}

.nav-right-item {
  color: #00416b;
  cursor: pointer;
  height: 80px;
  padding: 0 16px;
}

.nav-left {
  display: flex;
  justify-content: center;
  align-items: center;

}

.notification-dot {
  width: 9px;
  height: 9px;
  background: red;
  position: absolute;
  right: 4.5%;
  top: 39.5%;
  border-radius: 20px;
  border: 1px solid white;
}

.logo {
  padding: 0 75px;
}

.nav-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-right-item {
  height: 80px;
  padding: 0 16px;
  /* border-left: 1px solid #CCDAE4; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--dark-color);
}
  

/* .logout {
  padding-bottom: 10px;
} */

.logout-Modal h3 {
  color: var(--tbody-text);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
  padding: 16px;
  border-bottom: 1px solid var(--clr-divider);
}

.logout-Modal p {
  /* color: #001D30; */
  color: var(--tbody-text);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px;
}

.logout-Btns {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.logout-Btns button {
  height: 48px;
  border-radius: 8px;
  padding: 16px 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-cancel {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.logout-button {
  background: var(--polstrat-primary) !important;
  color: var(--white-color);
}

.grid-container {
  width: 100%;
  display: grid;
  margin-top: 80px;

  grid-template-columns: 0.05fr 1fr;
  /* grid-gap: 10px; */
  
}





.logout-Btns {
  display: flex;
  /* justify-content: flex-end; */
  padding: 16px;
}

.logout-Btns button {
  height: 48px;
  border-radius: 8px;
  padding: 16px 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .logout-cancel {
  border: 1px solid var(--polstrat-primary);
  color: var(--polstrat-primary);
}

.logout-button {
  background: var(--polstrat-primary) !important;
  color: var(--white-color);
} */
.user-profile {
  position: relative;
  /* display: inline-block; */

}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip1 {
  position: absolute;
  top: 150%;
  right: -160px;
  transform: translateX(-50%);
  width: 316px;
  height: 152px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--table-wrapper);
  box-shadow: 0 2px 12px 0 var(--modal-opacity);
  display: flex;
  align-items: center;
  padding-left: 30px;
  border: 1px solid var(--clr-divider);
}

.user-profile img {
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 93px;
  border: 1.938px solid var(--border-line, #CCDAE4);
  box-shadow: 0px 0px 11.625px 0px rgba(0, 0, 0, 0.25) inset;
  /* margin-left: 20px; */
}

.user-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  text-align: left;
}

/* .nav-right-item input {
  height: 35px;
  width: 320px;
  border-radius: 8px;
  padding: 2px 10px;
  border: 1px solid #CCDAE4;
  border-radius: 8px;
  line-height: 36px;
} */
.nav-right-item .username {
  -webkit-font-feature-settings: "clig" off,"liga" off;
  font-feature-settings: "clig" off,"liga" off;
  color: var(--dark-color);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /*  text-align: left; */
}
.user-name span:nth-child(2) {
  -webkit-font-feature-settings: "clig" off,"liga" off;
  font-feature-settings: "clig" off,"liga" off;
  color: var(--dark-color);
  opacity: 0.7;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}
.user-name span:nth-child(3) {
  color: #3bbee8;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  text-decoration-line: underline;
}

.dashboard-page-bg {
  background-color: var(--overview-background-color);
}

.toggle-btn {
  cursor: pointer;
  height: 22px!important;
}

.nivo-grid line {
  stroke-dasharray: 5, 5 !important; 
}




