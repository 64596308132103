@font-face {
  font-family: "Inter-Medium";
  src: url(../assets/fonts/Inter-Medium.ttf);

}

@font-face {
  font-family: "Inter-Regular";
  src: url(../assets/fonts/Inter-Regular.ttf);
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Inter-Regular";
  box-sizing: border-box;
  font-family: "Inter-Regular" !important;

}

/* .overview-page-container{
 margin: 15px;
} */

/* body {
  background-color: var();

} */

.overview-container {
  background-color: var( --overview-background-color);
  width: 100%;
  height: auto;
  margin: auto;
}




.overview-stats {
  height: 130px;
  padding-left: 30px;
  border-radius: 32px;
  display: flex;
  align-items: center;
    background: var(--wrapper-bg);
    border-radius: 32px;
    box-shadow: var(--box-shadow);
    display: flex;
    height: 110px;
    padding-left: 30px;

}

.overview-username{
color: var(--hello-text);
font-family: "Inter-Regular";
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding: 20px 20px 10px;
text-transform: capitalize;
}

.overview-username span{
 color: var(--primary-color);
}

.overview-stats .stats-icon {
  width: 71px;
  height: 71px;
  background: #d6f2fd;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 4.82539701461792px 8.44444465637207px 0px rgba(86, 150, 184, 0.2);

}

.stats-icon img {
  flex-shrink: 0;
}

.stats-wrapper .stats-title {
color: var(--stat-text);
font-family: "Inter-Regular";
font-size: 16.889px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.467px;
  margin-top: 5px;
  margin-left: 24px;
  padding-right: 10px;
}

.stats-content {
  display: flex;
  flex-direction: column;
}

.stats-content .float-child,
.stats-content .float-container {
  padding-right: 15px;
  float: left;
  margin-top: 10px;

}

.float-child .stats-stat {
  font-family: "Inter-Bold";

  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;
  color: var(--dark-color);
  margin-left: 24px;
}

.overview-container .overview-top .stats-content .float-container {
  padding: 1px;
  margin-top: 15px;
  width: 72px;
  height: 24px;
  /* background: #D9D9D9; */
  border-radius: 20px;
}

.overview-top, .overview-second-row{
  background: var(--stats-wrapper);
}
.overview-top, .overview-second-row,.overview-second-row-map{
  border-radius: 20px;

}

.overview-second-row{
  margin: 30px 0px;
  padding: 35px 15px;
}

.overview-second-row-map{
  background: var(--overview-map-bg);
  margin: 12px 0px;
  padding: 0px 15px;

}



.float-container .float-child-arrow {
  padding-right: 5px;
  float: left;
  color: var(--color-black);

}

.float-child-arrow .change-icon {
  margin-left: 18.37%;
  margin-right: 18.36%;
  margin-top: 18.41%;
  margin-bottom: 16.67%;
  color: var(--color-black);
}

.float-child-arrow .float-percent {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #27AE60;
}

.overview-user-section {
  /* width: 1382px;
  width: '98%'; */

  margin: 24px 0px;
}

.revenue-map {
  /* width: 675px; */
  margin: 24px 0px;
}

.map-traffic {
  height: 356px;

  margin: 24px 0px 24px 17px;
}


.overview-section {
  display: flex;
  flex-direction: column !important;
  border-radius: 12.063px;
  /* background: var(--table-background); */
  /* box-shadow: 0px 4.82539701461792px 8.44444465637207px 0px rgba(59, 132, 172, 0.20); */
  /* mix-blend-mode: luminosity; */
}

.overview-section .overview-heading {
  padding: 0px 30px 0px;
  /* background: var(--table-background); */
  /* border-radius: 12.063px 12.063px 0px 0px; */
}

.overview-heading .overview-text {
  font-family: "Inter-Regular";
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  color: var(--dark-color);


}

.revenue-map .revenue-map-chart rect {
  width: 8px;
  background: #004877;
  border-radius: 20px 20px 0px 0px;
}

/* .revenue-map .revenue-map-chart text{
  display: none;
} */
.gms-data {
  padding: 0 16px;
}

.gms-data p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1C2D37;
  margin: 8px 0px;
  font-family: "Inter-Regular";
  font-style: 'normal';

}

.gms-data h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;
  color: #001D30;
  /* margin:0 16px; */
  font-family: "Inter-Bold";

}

.gms-data .record-view a {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: var(--color-black);
  margin: 34px 16px 30px auto;
}

.gms-data .gms-record-table table,
.billing-record-table table {
  /* width: 400px; */
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px !important;
  background: var(--white-color);
}


.gms-record-table td,
.billing-record-table td {
  padding: 10px !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;

}

.gms-record-table th,
.billing-record-table th {
  padding: 5px !important;
  background: var(--white-color) !important;


}



.gms-record-table .ant-table-thead .ant-table-cell,
.billing-record-table .ant-table-thead .ant-table-cell {
  background: var(--white-color) !important;
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.65);
  padding-top: 10px !important;
  padding-bottom: 10px !important;

}





.gms-record-table tr:nth-child(odd),
.billing-record-table tr:nth-child(odd) {
  background: #F1F1F1;
  height: 21px;
}





.gms-record-table tr:nth-child(even),
.billing-record-table tr:nth-child(even) {
  background: var(--white-color);
  height: 21px;
}




.gms-record-table td:nth-child(2),
.gms-record-table thead tr th:nth-child(2) {
  text-align: flex-end;
  padding-right: 15px !important;
  padding: 10px;


}

.gms-record-table td:nth-child(1),
.gms-record-table thead tr th:nth-child(1) {
  padding-left: 10px !important;
}

.gms-record-table thead tr th:nth-child(2) {
  padding-right: 15px !important;

}

.billing-record-table thead tr th,
.billing-record-table td {
  text-align: start !important;
  padding-left: 18px !important;

}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}


.overview-container .page-heading button {
  display: none;
}


.gms-user-bill {
  margin: 0px 0px 10px 0px !important;
  padding: 0px 0px 15px;
}

.gms-user-bill .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0;
  display: none;
}

/* .active-user-map rect {
    width: 8px;
    border-radius: 20px 20px 0px 0px !important;
  
  } */

.insights-info h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;

  color: var(--color-black);
}

.insights-info p {

  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-top: 10px;
  color: var(--color-black);

}


.insights-info-sec {
  margin: 0px 10px;

  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}


/* .ant-table-pagination.ant-pagination{
      display: none;
  } */

.overview-error svg {
  width: 50px;
  height: 50px;
  color: rgba(0, 0, 0, 0.50);
}

.overview-error span {
  color: rgba(0, 0, 0, 0.50);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px;
  text-transform: capitalize;
}

.overview-heading .ant-select .ant-select-selection-placeholder {
  padding-top: 0px;
}

.overview-heading .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
}

.overview-heading .ant-select-selection-item {
  line-height: 8px !important;
  color: var(--input-color);
}
.overview-stats {
  transition: transform 1s ease, background-color 0.1s ease, border-color 0.5s ease;
}

.overview-stats:hover {
  transform: translateY(-10px);
  background-color:  var(--overview-card-hover-bg);

}
.apexcharts-toolbar{
  gap:8px;
  z-index: 9!important;
}

