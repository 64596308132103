.heading-text-div {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.title {
  color: var(--dark-color);
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.read-all-text {
  padding-top: 5px;

  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;

  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #004877;
}

.allNotifications {
  margin: 0 16px 10px 16px;
  background: var(--white-color);
  border: 1px solid var(--clr-divider);
  border-radius: 8px;
}

.notification-pagination-div {
  margin: 20px 20px 60px 20px;
}

.mark-all-read-btn.unread {
  color: red;
}
