.settings-text {
    margin: 15px 10px;
}

/* body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge 
     scrollbar-width: none; 
    overflow-y: scroll;  
  } */

/* body::-webkit-scrollbar {
    display: none;
} */

.settings-page {
    padding: 0px 24px 24px;

}

.settings-page .tabs {
    background: var(--table-wrapper);
    border-bottom: 1px solid #CCDAE4;
    /* box-shadow: 1px 1px 3px rgba(0, 72, 119, 0.15); */
    border-radius: 8px 8px 0 0;
}


.settings-page .tabs button:hover {
    color: #004877;
}


.settings-page .tabs button {
    padding: 8px 25px;
    width: 160px;
    height: 56px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color:  #8b909a ;
    text-align: center;
    border: none;
    margin: 0;
}

.settings-page .tabs button.active {
    /* background-color: #F0F6F9 !important; */
    border-radius: 6.941px 0px 0px 0px;
    border-bottom: 2.314px solid var(--primary-color);
    border-radius: 0;
    color:  var(--primary-color);
    font-size: 16px;
    font-weight: 600;
}

.settings-page .tabs button:focus-visible {
    outline: none;
    outline-offset: unset;
    transition: unset;
}

.settings-page .tabs button:nth-child(1) {
    border-radius: 8px 0px 0px 0px;
}

.settings-page .tabs .ant-tabs-nav-wrap {
    border-bottom: 1px solid #CCDAE4;
    border-radius: 10px 10px 0px 0px;
}

.settings-page .ant-tabs .ant-tabs-ink-bar {
    background: var(--color-black) !important;
}

.text-heading {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 22px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--input-color);
}

.toggle-button {
    margin-left: 13rem;
}

.single-notification-text {
    padding: 10px 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #000000;

}



.notification-div {
    padding-bottom: 10px;
    border-bottom: 1px solid #CCDAE4;

}

.button-bottom-right {
    display: flex;
    justify-content: right;
}

.button-bottom-right .ant-btn {
    font-size: 14px;
    width: 160px;
    height: 48px;
    padding: 10px 32px;
    border-radius: 6px;
    margin: 15px 10px;
    transition:none;
}

.button-bottom-right .cancle-btn {

    color: #004877 !important;
    border: #004877 !important;
    border-radius: 8px;
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    width: 120px !important;


}

.upload-photo-btn,
.delete-photo-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    gap: 10px;
    width: 160px;
    height: 48px;
    border: 1px solid var(--primary-color)!important;
    border-radius: 8px;
    height: 40px;
}

.delete-photo-btn {
    border-radius: 8px;
    margin-top: 15px;
    color: var(--primary-color) !important;
}

.upload-photo-btn {
    background: var(--polstrat-primary) !important;

}

.profile-pic-buttons-div {
    margin-bottom: 50px;
}

.Profile-Picture {
    width: 9rem;
    height: 9rem;
    float: left;
}

.profile-pic-buttons {
    margin-top: 20px;
    margin-left: 20px;
    padding-top: 1px;
}

.profile-pic-buttons Button {
    display: block;
    transition: none;
}

.username-password-fields {
    display: flex;
    padding-bottom: 30px;
    width: 65rem;
}



.username-field {
    flex: 1;
}

.username-field p {
    padding-bottom: 5px;
    font-size: 17px;
}

.password-field {
    padding-left: 25px;
    flex: 1;
}

.password-field p {
    padding-bottom: 5px;
    font-size: 17px;
}

.change-password-link {
    flex: 1;
    padding-top: 40px;
    padding-left: 20px;
    font-size: 14px;
}
.bio-field{
 width: 78%;
}


.bio-field-div {
    padding-bottom: 20px;
}

.bio-field-div p {
    padding-bottom: 5px;
    font-size: 17px;
}

.deactivate-account-div p {
    padding-bottom: 10px;
    font-size: 17px;
}

.deactivate-account-div {
    padding-bottom: 100px;
}

.change-password-link a {
    color: var(--color-black);
    text-decoration: underline;
    font-weight: bold;
}

.FirstTab label {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    display: inline-block;
    color: var(--input-label);
}

.text-heading-general-info {
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: var(--dark-color);
}


.firstname-lastname-fields {
    display: flex;
    padding-bottom: 30px;
    width: 50rem;
}

.firstname-field {
    flex: 1;
}

.firstname-field p {
    padding-bottom: 5px;
    font-size: 17px;
}


.lastname-field {
    padding-left: 25px;
    flex: 1;
}

.lastname-field p {
    padding-bottom: 5px;
    font-size: 17px;
}

.alternate-email-text {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

}

.alternate-email-text p {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: nomal;
    text-decoration-line: underline;
    color: var(--polstrat-primary);

}

.alternate-email-text svg {
    color: var(--color-black);
}

.deactivate-account-field{
    margin: 40px 0px;

}

.general-form {
    padding: 15px 36px;
    padding-bottom: 12rem;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
    background: var(--table-wrapper);
    border-radius:0 0 8px 8px;
}

.general-form input {
    margin-top: 8px;
    height: 48px;
    border: 1px solid var(--border-line);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--input-color);
    background: var(--input-bg);


}

.profile-form input {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--input-color);
}

.button-bottom-right .cancle-btn {
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

/* .settings-page .ant-switch.ant-switch-checked {
    background: var(--color-black);
}
.settings-page .ant-switch:hover:not(.ant-switch-disabled) {
    background: var(--white-color)!important;
    border:2px solid var(--color-black);
}
.settings-page .ant-switch .ant-switch-handle::before  {
    border:2px solid var(--color-black)!important
} */

/* .ant-switch .ant-switch-inner {
    background: #3BBEE8;
} */

.ant-switch .ant-switch-inner {
    background: linear-gradient(180deg, rgba(59, 190, 232, 0.72) 0%, rgba(0, 72, 119, 0.72) 100%);
    width: 50px;
    height: 26px;
}

.ant-switch .ant-switch-handle::before {
    border-radius: 50%;
    background: var(--light-color);
    inset-inline-end: 3px;
    inset-inline-start: -3px;
}

.ant-switch .ant-switch-handle {
    width: 22px;
    height: 22px;
}

.ThirdTab {
    padding: 0px 15px 15px;
    background-color: var(--table-wrapper);

}

.button-bottom-right {
    border-top: 1px solid #CCDAE4;
    border-radius: 0px 0px 8px 8px;
    background: var(--table-wrapper)
}

.SecondTab label {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--tbody-text);
    opacity: 0.65;
}

.password-field span,
.password-field input {
    background: var(--password-input-bg);
}

.SecondTab .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    /* border-color: #001D30; */
    background: #004877;
}

.settings-page .ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-item {
    color: #000;
    opacity: 1;
    line-height: 30px;
}

.settings-page .ant-select .ant-select-arrow {
    color: #00416B !important;
    line-height: 48px;
}


.profile-pic-buttons-div img {

    border: 1px solid #CCDAE4;
    box-shadow: inset 0px 0px 6px rgba(1, 29, 47, 0.25);
    border-radius: 50%;
    width: 150px;
    height: 150px;
    /* display: inline; */
    align-items: center;

}

.profile-pic-buttons-div {
    margin-top: 20px;
    border: 1px solid #CCDAE4;
    box-shadow: inset 0px 0px 6px rgba(1, 29, 47, 0.25);
    border-radius: 50%;
    width: 150px;
    height: 150px;
    /* display: inline; */

}


.settings-page .page-heading button {
    display: none;

}

.bg-white {
    background: var(--table-wrapper);
}

.FirstTab,
.SecondTab,
.ThirdTab {
    box-shadow: 1px 1px 3px rgba(0, 72, 119, 0.15);
    border-top: none !important;
    border-radius: 0 0 8px 8px;
}



.subscription {
    border-radius: 12.063px;
    margin: 20px 40px 0px 20px;
    padding: 20px;
    background: var(--input-bg);
    box-shadow: 0px 4.8254px 8.44444px 0px rgba(59, 132, 172, 0.20);
}

.subscription h1 {
    color: var(--primary-color);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.wrapper {
    width: 80%;
}

.right-side, .left-side{
    
    width: 50%;
}

.basic{
    display: flex;
    width: 100%;
}

.subscription p span:nth-child(1) {
    color: var(--tbody-text);
    opacity: 0.65;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 27%; /* Adjust the width to 40% */
    margin-bottom: 20px;
}

.subscription p span:nth-child(2) {
    color: var(--tbody-text);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;

}

.setting-search-container .search-filter .filter-option {
    display: none;
}

 .ant-btn-primary:disabled {
    color: hsla(0,0%,100%,.25);
}
.billing-table{
    border: 1px solid var(--table-border);
    border-radius: 0 0 8px 8px;
}


