.get-password {
    max-width: 496px;
    /* border: 1px solid #CCDAE4; */
    margin: auto;
    /* margin-top: 10%; */
    border-radius: 8px;
    /* padding: 2rem; */
    /* height: 338px; */
    background-color: var(--white-color);
}
.get-password img{
    display: block;
    margin: auto;
    padding-bottom: 24px;
}