  :root {

    --polstrat-primary: #004877;
    --white-color: #FFFFFF;

    --tabs-border-bottom: #DBDADE;
    --table-border: transparent;
    --thead-text: #8B909A;
    --pagination-active-text: #FFF;

    /* Primary color */

    /* light theme color codes */
    /* --white-color: #FFFFFF; */

    /* body backgrounf color */
    --background-color: #F2F8FD;
    --overview-background-color: #FFF;

    /* --primary-color: #FFFFFF; */
    /* --color-black: #000; */

    /* --secondary-color: #E2E2E2;
    --active-icon-color: #2B2B2B;
    --sidebar-icon: #595959; */

    --border-line: #CCDAE4;
    --border-line1:#C5DEFF;
    --clr-divider: #ccdae4;
    --border:transparent;
    --track-bg:#f2f2f2;

    /* Heading colors */
    /* --text-h3: #001D30; */

    /* Button Colors */
    /* --btn-primary: #004877 */

    /*  */
    --background-color: #F2F8FD;
    --light-color: #FFF;
    --dark-color: #000;
    --stat-text: rgba(0, 0, 0, 0.50);
    --primary-color: #004877;
    --slider-background: #E8E8E8;
    --slider: #7A7A7A;
    --linear-color: linear-gradient(180deg, #C837AB 0%, #FFA44B 100%);


    /* sidebar */

    --sidebar-bg: linear-gradient(180deg, #095689 0%, #0c3b59 34.90%, #07263c 66.15%), url('../assets/images/sidebarImg.png');
                  
    /* table */
    --table-background: #FFF;
    --table-wrapper: #FFF;
    --thead-text: #8B909A;
    --tbody-text: :#1C2D37;
    --tabs-active: #004877;
    --para-text: #8B909A;
    --pagination-dropdown: #23272E;
    --pagination-background: #F1F2F6;
    --pagination-active-background: #004877;
    --pagination-text: #8B909A;
    /* --table-row-hover: #fafafa; */
    --table-action-svg: #004877;
    --search-input-border: 1px solid rgba(0, 0, 0, 0.15);
    --table-row-hover: #F6FCFF;
    --date-picker: rgba(0, 0, 0, 0.88);
    --input-disabled: rgba(0, 0, 0, 0.04);
    --office-stats-text: #59748A;
    --open-tickets: rgb(0, 0, 0);
    --assigned-status: invert(0);
    --overview-stats-shadow: 0px 4.825px 8.444px 0px rgba(59, 132, 172, 0.20);
    --tag-type-bg: rgba(138, 212, 255, 0.325);

    /* modal */

    --input-bg: #FFF;
    --password-input-bg: #F4F8FB;
    --input-color: #001D30;
    --input-label: rgba(0, 0, 0, 0.65);
    --input-placeholder: rgba(0, 0, 0, 0.6);
    --search-input-selected: #e6f4ff;


    --modal-header: #FFF;
    --modal-bg: #F2F8FD;
    --modal-h3: #001D30;
    --modal-close-icon: #000;
    --modal-opacity: rgba(0, 0, 0, 0.45);

    /* overview page */
    --hello-text: #000;
    --graph-date: rgba(0, 0, 0, 0.70);
    --stats-wrapper: #F2F8FD;
    --wrapper-bg: #FFF;
    --overview-map-bg: #F2F8FD;
    --overview-nav-bg: #FFF;
    --line-graph-bottom: #9DA1A4;
    --overview-dropdown: rgba(59, 190, 232, 0.59);
    --dropdown-selected: #e6f4ff;
    --overview-card-hover-bg: #bfd6e6;

    /* Notifications */
    --notification-background: #F0F6F9;
    --notification-read-background: #FFF;
    --notification-unread-background: rgb(240, 246, 249);
    --notification-title: #001D30;
    --notification-content: #1C2D37;

    /* cdm */
    --constituency-name-span: rgba(38, 50, 56, 0.7);
    --constituency-bg: #fff;
    --cnstituency-data-table: rgba(59, 190, 232, 0.06);



    /* gms */
    --gms-stats-bg: #fff;
    --gms-stats-bg-shadow: 0px 4px 7px 0px rgba(59, 132, 172, 0.2);
    --tabs-active-color: #004877;

    /* search placeholder */
    --search-placeholder: #004877;

    /* action svg */
    --action-svg-fill: #004877;

    /* Modal */
    --modal-header: #FFF;
    --modal-bg: #F2F8FD;
    --modal-h3: #001D30;

    --box-shadow: 0px 4px 10px 0px rgba(59, 190, 232, 0.25);
  }

  .darkMode {
    /* body backgrounf color */
    --background-color: #0D1416;
    --overview-background-color: #0D1416;
    --border-line: none;
    --clr-divider: rgba(255, 255, 255, 0.1);
    --border:#FFFFFF;
    --border-line1:#787878;
    --track-bg:rgba(255, 255, 255, 0.1);

    /*  */
    --light-color: #000;
    --dark-color: #FFF;
    --primary-color: #FFF;
    --stat-text: rgba(255, 255, 255, 0.50);
    --slider-background: #9E9E9E;
    --slider: #FFF;
    --office-stats-text: #FFF;
    --open-tickets: rgba(255, 255, 255, 0.50);
    --assigned-status: invert(1);
    --overview-stats-shadow: 0px 4.825px 8.444px 0px rgba(59, 132, 172, 0.20);
    --tag-type-bg: transparent;
    /* sidebar */
    /* --sidebar-bg: #131313; */
    --sidebar-bg: linear-gradient(180deg, #095587 0%, #0D3C5A 35.66%, #08273C 58.85%), url('../assets/images/sidebarImg.png');


    /* table */
    --table-background: #0D1416;
    --table-wrapper: #1D282C;
    --thead-text: #ACB1BC;
    --tbody-text: #FFF;
    --tabs-active: #FFF;
    --para-text: #ACB1BC;
    --pagination-dropdown: #FCFCFC;
    --pagination-background: #2e2e2e;
    --pagination-active-background: #010101;
    --pagination-text: #ACB1BC;
    --table-action-svg: #FFF;
    --search-input-border: 1px solid #FFFFFF;
    --table-row-hover: rgba(29, 40, 44, 1);
    --date-picker: #FCFCFC;
    --input-disabled: rgba(255, 255, 255, 0.20);
    --table-border:#262C2E;



    /* modal */

    --input-bg: #0D1416;
    --password-input-bg: #2E2E2E;
    --input-color: #FFF;
    --input-label: #FFFFFF;
    --input-placeholder: #FFF;
    --search-input-selected: #000;



    /* Modal */
    --modal-header: #171616;
    --modal-bg: #2E2E2E;
    --modal-h3: #FFF;
    --modal-close-icon: #fff;
    /* --modal-opacity: rgba(255, 255, 255, 0.20); */
    /* --modal-opacity:rgba(242, 235, 233, 0.175); */
    --modal-opacity:rgba(13, 20, 22, 0.555);



    /* overview page */
    --hello-text: rgba(255, 255, 255, 0.70);
    --primary-color: #FFF;
    --graph-date: rgba(255, 255, 255, 0.70);
    --stats-wrapper: #1D282C;
    --wrapper-bg: #0D1416;
    --overview-map-bg: #1D282C;
    --overview-nav-bg: #1D282C;
    --line-graph-bottom: #9DA1A4;
    --overview-dropdown: #FFF;
    --dropdown-selected: rgba(0, 0, 0, 0.34);
    --overview-card-hover-bg: #171616;


    /* Notifications */
    --notification-background: #616161;
    --notification-read-background: #0D1416;
    --notification-unread-background: rgba(255, 255, 255, 0.20);
    --notification-title: #FFF;
    --notification-content: #FFF;


    /* cdm */
    --constituency-name-span: #fff;
    --constituency-bg: #0D1416;
    --cnstituency-data-table: #0D1416;
    ;



    /* gms */
    --gms-stats-bg: #3D3C3C;
    /* --gms-stats-bg-shadow:0px 4px 7px 0px rgba(59, 132, 172, 0.20); */
    --gms-stats-bg-shadow: none;
    --tabs-active-color: #FFFFFF;

    /* search placeholder */
    --search-placeholder: #FFF;

    /* action svg */
    --action-svg-fill: #fff;

    --box-shadow: none;
  }


  .switch {
    display: flex;
    /* width: 100vw; */
    /* height: 100px; */
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 28px;
    right: 165px;
  }