.search-filter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 16px;
    margin: 16px 0;
}

.search-filter .filter-option {
    width: auto;
    height: 40px;
    background: var(--white-color);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 10px;
    font-size: 15px;
    /* font-family: Public Sans; */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.43px;
    color: #8B909A;
}

.filter-option svg {
    height: 12px;
    width: 16px;
    margin-left: 1px;
    margin-bottom: 5px;
}


.search-option{
    display: flex;
} 
.search-filter .search-option input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 330px;
    height: 40px;
    padding: 8px;
    gap: 8px;
    background: transparent;
    border: var(--search-input-border) !important;
    border-radius: 4px;
    margin-left: 16px;
    /* text-indent: 24px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    /* color: rgba(0, 0, 0, 0.5); */
    color: var(--dark-color);
    opacity: 0.98;
}

.search-option img {
    opacity: 0.9;
    margin-left: -30px;
}

.search-option input::placeholder {
    color: var( --search-placeholder)!important;
  }