.constituency-section .page-heading button {
  display: none;
}

.view-constituency {
  margin: 16px 24px 16px 20px;
}

.constituency-name p {
  color: #3bbee8;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
}

.constituency-name p span {
  color: var(--constituency-name-span);
  font-size: 20px;
  font-weight: 400;
}

.constituency-name p svg {
  color: #3bbee8;
}

.view-constituency .search-filter {
  padding: 0 30px 0;
}

.view-constituency .search-filter .search-option input {
  width: 240px;
}

.view-constituency .ant-select-selector {
  width: 152px !important;
  height: 40px !important;
  background: #ffffff;
  border: 1px solid #ccdae4 !important;
  border-radius: 8px;
}

.view-constituency .ant-select-selection-item {
  line-height: 18px !important;
}

.view-constituency .ant-select-selection-placeholder {
  line-height: 38px !important;
}

.view-constituency button {
  height: 40px;
}

.view-constituency .fileUpload .reset-button {
  height: unset;
  /* border-radius: 0; */
  box-shadow: none;
  background: transparent;
  color: var(--dark-color);
  padding: 0;
}

.view-constituency .constituency-count p {
  color: var(--dark-color);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}

.view-constituency .constituency-count p span {
  font-weight: 700;
}

.constituency-info .firstname-field p span:first-child {
  display: inline-block;
  width: 22%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #8b909a;
  /* margin-bottom: 10px; */
}

.constituency-info .firstname-field p span:nth-child(2) {
  color: var(--tbody-text);
}

.constituency-info .add-user-modal-body {
  border-radius: 10px;
}

/* VILLAGE DATA */
.village-data-wrapper {
  /* background: url('../assets/images/villageDataImg.png'); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.village-data-tooltip {
  z-index: 1;
  border-radius: 6px;
  background: rgba(243,
      251,
      254,
      0.5);
  /* The last value (0.8) sets the opacity */
  width: fit-content;
  margin: 8px 8px 0 auto;
  padding: 10px;
}

.village-data-tooltip h3 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.village-data-tooltip .village-type p {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.467px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 2.42254px 4.23944px 0px rgba(59, 190, 232, 0.25);
}

.tooltip-options div:first-child {
  border-radius: 5px;
  border: 2px solid #3bbee8;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(59, 190, 232, 0.25);
}

.tooltip-content {
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  align-items: center;
  width: -webkit-fill-available;
  height: 40px;
  margin: 2px 0;
}

.village-data-tooltip .tooltip-content p {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  border-radius: 0;
  box-shadow: none;
  padding: 0 5px;
}

.tooltip-content svg {
  color: #3bbee8;
  font-size: 10px;
  font-weight: 700;
  /* margin-top: 4px; */
}

.tooltip-content div p {
  color: #3bbee8 !important;
  font-size: 12px;
  font-weight: 700;
  padding: 0;
}

.tooltip-content span {
  color: rgba(0, 0, 0, 0.51);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  display: inline-block;
  padding: 0 5px;
}

.constituency-graph-modal {
  border-radius: 10px;
  background: var(--table-wrapper);
  box-shadow: 1px 5px 10px 0px rgba(1, 29, 47, 0.25);
  padding: 30px;
}

.constituency-graph-modal .constituenct-data-table .legend {
  margin-left: 80px;
}

/* CONSTITUENCY DATA */
.constituency-data {
  border-radius: 10px;
  background: var(--constituency-bg);
  padding: 16px;
  border: 1px solid var(--table-border);
}

.constituency-data h4 {
  color: var(--primary-color);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.18px;
  padding: 14px 18px;
}

.constituenct-data-table {
  border-radius: 8px;
  background: var(--cnstituency-data-table);
  padding: 10px;
  min-height: 390px;
  border: 1.5px solid var(--table-border);
}

.constituenct-data-table .ant-select {
  border: 1px solid var(--border) !important;
}

.constituenct-data-table h3 {
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 5px 0;
}

.constituenct-data-table h1 {
  /* color: rgba(0, 0, 0, 0.7); */
  color: var(--dark-color);
  opacity: 0.7;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 5px 0;
}

.constituenct-data-table .ant-table-tbody>tr {
  height: 40px;
}

.constituenct-data-table .ant-table-tbody tr:nth-child(even) {
  background: none !important;
}

.constituency-data .ant-table-wrapper .ant-table,
.constituency-data .ant-table-thead .ant-table-cell {
  background: transparent !important;
  border-radius: 8px;
}

.constituency-data .ant-table-wrapper .ant-table-tbody>tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.constituency-data .ant-table-wrapper .ant-table-tbody>tr:last-child td {
  border-bottom: none !important;
  font-weight: 600;
}

.constituenct-data-table .legend {
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 80px;
}

.constituenct-data-table .ant-select-selector {
  /* width: 152px !important; */
  height: 36px !important;
  background: #ffffff;
  border: none !important;
  border-radius: 8px;
}

.map-container {
  width: 100%;
  height: 100%;
  /* padding: 15px; */
  border-radius: 8px;
  /* background: rgba(59, 190, 232, 0.06); */
}

.loader {
  width: 100%;
  height: 100vh;
}

.no-data-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70%;
  /* background: red; */
}

.no-data-icon h1 {
  color: #004877;
  font-size: 14.31px;
}

.ant-skeleton-paragraph li {
  height: 30px !important;
}

.constituency-info .reports-modal-body {
  padding: 24px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background: var(--background-color);
  border-radius: 0 0 8px 8px;
}

.gm-style-iw-chr {
  position: absolute;
  overflow: hidden;
  right: -5px;
  top: -12px;
}

.custom-chart-toolip {
  display:inline-flex;
  flex-direction: column;
  align-items:self-start;
  justify-content:center;
  padding:16px 5px 0;
  border-radius: 3px;
}
