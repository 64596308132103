.user-info-container .page-heading button {
  display: none;
}

.table-section {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0.706612px 0.706612px 2.11983px rgba(0, 72, 119, 0.15);
  border-radius: 8px;
  margin-top: 20px;
  background: var(--table-wrapper);
  
}

.tab-change {
  border-bottom: 1px solid #dbdade;
  height: 58px;
}



.user-info-container .client-pagination {
  position: absolute;
  width: -webkit-fill-available;
}

.user-info-tabs button {
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #8b909a;
  padding: 18px 60px !important;
  margin-left: 20px;
}

.user-info-tabs .active {
  /* padding: 20px; */
  text-align: center;
  cursor: pointer;
  outline: none;
  border-bottom: 3px solid var(--primary-color);
  border-radius: 0;
  color: var(--primary-color);
}

.userinfo-breadcrumbs {
  height: 45px;
  border-radius: 8px;
  border: 1px solid #2b9fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
  padding-left: 20px;
}

.userinfo-breadcrumbs h1 {
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #667085;
  cursor: pointer;
}

.userinfo-breadcrumbs span {
  color: var(--primary-color);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.middle-section {
  margin: 20px;
}

.userinfo-charts .charts {
  border-radius: 10px;
  background: var(--table-background);
  box-shadow: 4px 4px 7px 0px rgba(59, 132, 172, 0.2);
}

.tms-stats-col {
  border: none;
}

.chart-title {
  border-bottom: 1px solid #ccdae4;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.chart-title p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* Body text */

  color: var(--tbody-text);
}

.chart-title h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16.9587px;
  line-height: 21px;
  letter-spacing: -0.01em;
  color: var(--input-color);
  text-align: left;
}

.chart-title .img-wrapper {
  /* background: #F2F2F2;
    border-radius: 4.23967px;
    padding: 15px; */
  margin-right: 20px;
}

.gms-table .tms-list .ant-space {
  width: auto;
  border-radius: 8px;
  border: none;
  background: none;
  padding: 10px;
}

.gms-table .tms-list .view-more-details {
  width: auto;
  border-radius: 8px;
  border: 1px solid #bbb;
  padding: 10px;
}

/* .gms-table .tms-list span {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--polstrat-primary);
} */

/* MORE DETAILS CSS */
.more-details-wrapper {
  margin: 20px;
}

.gms-all-details,
.gms-assign-task {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: var(--table-wrapper);
}

.details-heading {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid var(--clr-divider);
  background: var(--table-wrapper);
  padding: 20px 10px;
  display: flex;
  align-items: center;
}

.details-heading h3 {
  color: var(--input-label);
  font-family: "Inter-Regular";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.gms-more-details .gms-all-details .issue-details,
.gms-assign-task .issue-details {
  padding: 16px !important;
  height: 72vh;
  overflow-y: scroll;
  background: var(--table-wrapper);
}

.tms-more-details .gms-all-details .issue-details {
  padding: 16px !important;
  height: 72vh;
}

.gms-container p {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
}

.gms-container p span:first-child {
  width: 30%;
}

.gms-all-details .issue-details span,
.gms-assign-task .issue-details span {
  color: var(--input-color);
  text-transform: capitalize;
}

.gms-all-details .issue-details p span:first-child,
.gms-assign-task .issue-details p span:first-child {
  color: var(--tbody-text);
}

.gms-all-details .issue-details h2,
.gms-assign-task .issue-details h2 {
  color: var(--primary-color);
}

.gms-all-details .issue-details .gms-details-btn .ant-btn {
  display: block !important;
  margin: auto;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
}
.gms-details-btn .ant-btn img {
  filter: var(--assigned-status);
}
.profile-pic {
  padding: 25px 0;
}

.profile-pic img {
  border-radius: 75px;
  display: block;
  margin: auto;
  width: 9rem;
  height: 9rem;
}

.user-priority {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  padding: 0 10px;
}

.user-priority img {
  width: 17px;
  height: 17px;
}

.approval {
  display: flex;
  align-items: center;
  border-radius: 20px;
  /* border: 0.5px solid var(--input-color); */
  padding: 5px 10px;
  margin: 0 10px;
}

.approval span {
  color: var(--input-color);
  font-weight: 600;
}
