* {margin: 0;padding: 0;box-sizing: border-box;}

.user-section {margin: 0 16px;}

.hide-button {display: none !important;}


.user-section .page-heading {padding-left: 5px;padding-right: 0;}

/* USER TABLE  */
.user-table-wrapper {background: var(--table-background);border-radius: 10px;}

.user-table-wrapper .search-filter .filter-option {display: none;}

.user-table-wrapper .ant-table-tbody>tr>td:nth-child(8) {
    font-weight: 700;
}

.user-table-inner .anticon svg {
    display: inline-block;
    font-size: 22px;
    width: 34px;
    cursor: pointer;
}

.user-table-inner.ant-table-wrapper .ant-table-thead>tr>th,
.user-table-inner.ant-table-wrapper .ant-table-tbody>tr>td {
    text-align: start;
}

.user-table-wrapper .ant-table-thead>tr>td {
    border-bottom: none !important;
}

.user-table-wrapper .contact-info span {
    color: var(--polstrat-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.contact-info svg {
    color: var(--polstrat-primary);
}
/* ADD NEW USER MODAL*/

.user-info-modal .modal-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.user-info-modal .modal-header h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: var( --dark-color);
    padding: 16px;
}

.user-info-modal .user-modal-body-wrapper {
    padding: 24px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background: var(--background-color);
}

.user-info-modal .user-modal-body {
    border-radius: 10px;
    background: var(--table-wrapper);
    padding: 20px;
}

.user-modal-body h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: var(--dark-color);
}

.user-modal-row label {
    display: block;
    padding-top: 25px;
    padding-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--input-label);
}

.user-modal-row input {
    height: 48px;
    background: var(--input-bg);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}

/* .user-modal-body .ant-input-group-addon {
    background: var(--white-color) !important;
} */


.user-modal-wrapper .modal-footer-button {
    margin: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: var(--white-color);
    background: var(--polstrat-primary);
    border-radius: 8px;
    padding: 15px 20px;
}

.user-modal-wrapper .ant-select-selection-placeholder {
    line-height: 45px !important;
}

/* User Tabs */
.user-table-tabs {
    display: flex;
    border-bottom: 1px solid #DBDADE;
    height: 56px;
    background: var(--table-background);
    padding-left: 16px;
    border-radius: 10px 10px 0 0 !important;
}


.user-table-tabs button {
    padding: 20px 30px;
    color: #8b909a;
    font-size: 17.354px;
    font-style: normal;
    font-weight: 500;
    line-height: 25.452px;
}

/* .user-table-tabs button:hover{
    background: #e9f3f8;
} */

.user-table-tabs .active {
    padding: 20px 30px;
    text-align: center;
    cursor: pointer;
    outline: none;
    border-radius: 6.941px 0px 0px 0px;
    border-bottom: 2.314px solid var(--tabs-active);
    color: var(--tabs-active) !important;
    width: auto;
    font-weight: 600;
}

.user-table-tabs a {
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #8B909A !important;
    padding: 20px 50px !important;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.user-modal-wrapper button:disabled {
    background-color: rgb(166, 166, 168) !important;
}

.ant-message-notice-content.ant-message-error {
    left: 36%;
    z-index: 99;
    width: 320px;
    height: 40px;
    background: #00243C;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 18px;
    color: var(--white-color);
    padding: 10px;
}
.user-modal-row .password .ant-input{
    height: 38px;
}
.client-first-name input{
    width: 75%!important;
}
.client-first-name .ant-select-selection-item{
    text-overflow: unset!important;
    line-height: 25px!important;
}