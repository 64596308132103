.get-started {
    width: 1370px;
    height: 56px;
    background: #F2F2F2;
    border: 1px solid grey;
}

.helpcenter-page {
    background-color: #FAFAFA;;
    height: 100vh;
}

.helpcenter-common {
    padding: 30px 40px;
    background: var(--table-wrapper);
    margin: 20px;
    border-radius: 10px;
}

.helpcenter-common h3{
    font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
color: var(--dark-color);

}

.helpcenter-common h6{
font-family: Inter;
font-size: 16px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
color: var(--dark-color);


}

.icon-clr{
    color: var(--primary-color)!important;
}

.help-topic {
    width: 100%;
    height: 160px;
    background: var(--input-bg);
    border-radius: 8px;
    border: 1px solid var(--border-line, #CCDAE4);
    box-shadow: 1px 1px 2px 0px rgba(0, 72, 119, 0.15);
}

.useful-videos {
    width: 100%;
    height: 200px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.helptopic-innerbox {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: var(--highlight, #F0F6F9);
    border: none;
    margin: 16px 16px 24px 16px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.helptopic-innerbox img{
    height: 20px;
    width: 20px;
}

.help-subpara {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    /* color: rgba(0, 0, 0, 0.65); */
    color: var(--tbody-text);
    opacity: 0.65;
}


.heading-icon{
    width: 22px;
    height: 18px;
}

.helpcenter-heading .page-heading button {
    display: none;
}