@font-face {
  font-family: "Roboto-Medium";
  src: url(../assets/fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(../assets/fonts/Roboto-Regular.ttf);
}

.hierarchy {
  padding: 20px;
  overflow-x: auto;
}

.hierarchy.page-heading button {
  display: none;
}

.styled-node {
  padding: 10px 14px;
  border-radius: 8px;
  display: inline-flex;
  border-radius: 12.88px;
  background: var(--table-wrapper);
  box-shadow: 2.57597px 2.57597px 12.87985px 0px rgba(0, 72, 119, 0.10);
  /* width: 177.742px;
  height: 68.907px; */
  align-items: center;
  justify-content: space-around;
  margin: 5px 0;
}
.hierarchy-info{
  padding-inline: 10px;
}
.hierarchy-info span:first-child,
.styled-node.level-1 span,
.styled-node.level-3 {
  color: var(--primary-color);
  font-family: 'Roboto-Medium' !important;
  font-size: 12.88px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hierarchy-info span:nth-child(2) {
  color: var(--stat-text);
  font-family: 'Roboto-Regular' !important;
  font-size: 10.304px;
  font-style: normal;
  line-height: normal;
  padding-top: 6px;
}

.css-to7de5::after {
  content: '';
  position: absolute;
  top: 0;
  left: calc(50% - 5px);
  height: 10px;
  border-radius: 50%;
  width: 10px;
  background: var(--primary-color);
  border-left: none;
}

.office-node {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.office-name {
  position: relative;
  border: 1.288px solid #d9dde2;
  border-radius: 12.88px;
  box-shadow: none;
  height: 41.216px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: max-content;
  color: var(--dark-color);
}

.org-img {
  width: 50px;
  border-radius: 50%;
}
.org-img img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}