.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  /* height: 100vh; */
}

#video-bg {
  position: absolute;
  /* top: 50%; */
  left: 22%;
  /* min-width: 100%;
  min-height: 100vh; */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.get-in .logo {
  padding: 0;
  display: block;
  margin-inline: auto;
  padding-bottom: 100px;
}

.forgotPage-logo {
  z-index: 1;
  position: relative;
  margin-inline: auto;
  /* margin: auto; */
  padding: 15% 5% 0 0;
  height: fit-content;
}

.signup-page {
  /* height: 100vh;
  background-color: var(--white-color); */
  position: relative;
  z-index: 1;
  height: 100vh;
}

.signup-page-background {
  background: linear-gradient(180deg, #095689 0%, #0C3B59 34.90%, #07263C 66.15%), url('../assets/images/servicesBg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: soft-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 2rem; */
}

.signup-page-background h1 {
  color: var(--white-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
  padding: 50px 0 14px;
}

.signup-page-background p {
  color: rgba(255, 255, 255, 0.70);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 22px;
  max-width: 497px;
}

/* .get-in {
  max-width: 496px;
  margin: auto;
  background: var(--white-color);
} */
.get-in {
  max-width: 45%;
  /* margin: auto; */
  background: var(--table-background);
  width: -webkit-fill-available;
  border-radius: 0px 50px 50px 0px;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: center;
  margin: auto;
  padding-inline: 5%;
  box-shadow: 0px 2px 5px 0px var(--office-stats-text);
}

.get-in form {
  width: -webkit-fill-available;
}

.get-start {
  max-width: 496px;
  border: 1px solid #CCDAE4;
  margin: auto;
  margin-top: 5%;
  border-radius: 8px;
  padding: 2rem;
  height: 633px;
  background: var(--white-color);
}

.text-content {
  /* width: 382px; */
  height: 39px;
  /* margin: auto; */
}

.signup-page h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  /* text-align: center; */
  color: var(--input-color);
}

.sign-in-para {
  /* width: 388px; */
  height: 22px;
  /* margin: 8px auto; */
}

.sign-in-para p {
  font-weight: 400;
  font-size: 18px !important;
  line-height: 21px;
  font-style: normal;
  color: var(--tbody-text);
}

.text-error {
  color: rgb(161, 14, 4);
}

.signup-page .animate-spin {
  width: 1.5rem;
  height: 1.5rem;
}

.signup-page button:disabled {
  opacity: 0.7;
}

.password .ant-input,
.password span {
  border: none !important;
  background: none !important;
}

.signup-page label {
  color: var(--tbody-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  opacity: .65;
}

.signup-page input {
  background-color: var(--table-border);
  line-height: 32px !important;
  border: 1px solid var(--border-line);
  color: var(--dark-color);
}

.signup-page .ant-input {
  border: 1px solid var(--border-line);
  background-color: var(--table-border);
  border-radius: 8px;
  color: var(--dark-color);
  line-height: 32px !important;
  transition: none !important;
}
.signup-page .ant-input-affix-wrapper,
.signup-page .ant-input-affix-wrapper >input.ant-input {
  background-color: var(--table-border);
}
.forgot-pass-text {
  color: var(--primary-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.resend-link{
  color: #004877;
}
.sign-in-para .disabled{
  color: var(--input-disabled);
}