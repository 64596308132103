.tms-stats-col {
  border-radius: 8px;
  text-align: center;
}
.tms-stats-col img {
  height: auto;
  width: 47px;
}
.grid-child-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 140px;
}
.tms-stats-col .stats-title {
  background: rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid #ccdae4;
  text-align: center;
  height: 40px;
  padding-top: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-black);
}
.tms-page-container {
  padding: 15px;
}
.tms-page-container .tms-tabs-container {
  border: 1px solid #ccdae4;
  border-radius: 8px;
  margin-top: 30px;
}
.tms-tabs {
  box-sizing: border-box;
  width: 210px;
  height: 40px;
  display: flex;
  flex: unset;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
  margin: 20px 10px 0px;
}
.tms-tabs .active {
  color: var(--tabs-active-color);
  font-family: "Inter-Regular";
  font-size: 17.354px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.452px;
  border-radius: 6.941px 0px 0px 0px;
  border-bottom: 2.314px solid var(--tabs-active-color);
}
.tms-tabs button {
  color: #8b909a;
  font-size: 17.354px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.452px; 
}
.tms-tabs button:first-child {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 105px;
  height: 40px;
  margin-right: 20px;
}

.tms-tabs button:nth-child(2) {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 105px;
  height: 40px;

}
.tms-checkbox .checkbox {
  padding-top: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1c2d37;
}

.tms-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1c2d37;
}

.tms-checkbox
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #1c2d37;
  border-color: #1c2d37;
}

.tms-tabs .tms-icon {
  cursor: pointer;
  color: var(--color-black);
  width: 25px;
  height: 22px;
  margin-right: 5px;
}

.ant-dropdown .ant-dropdown-menu {
  padding: 5px !important;
  margin: 0px 0 -18% -4% !important;
}


.view-gms-details .modal-header p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;
  color: #001d30;
  padding: 16px;
  border-bottom: 1px solid #ccdae4;
  border-radius: 8px 8px 0px 0px;
  text-transform: capitalize;
}

.view-gms-details .ant-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #e5e7eb;
}
.view-gms-details .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}

.view-gms-details .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #001d30;
}

.view-gms-details .ant-tabs-tab:hover,
.view-gms-details .ant-tabs .ant-tabs-tab-btn:hover {
  color: #001d30;
}

.view-gms-details .ant-tabs .ant-tabs-tab-btn {
  transition: none;
}

.view-gms-details .ant-tabs .ant-tabs-tab {
  padding: 18px 30px;
}

.view-gms-details .ant-tabs .ant-tabs-ink-bar {
  background: #001d30;
}

.gms-view-tabs .gms-modal .modal-body {
  padding: 0 20px !important;
}

.gms-modal .gms-details-btn {
  display: flex;
  justify-content: flex-end;
}

.gms-details-btn .ant-btn {
  height: 48px;
  background: transparent;
  border-radius: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: var(--color-black);
  padding: 8px 24px;
  display: inline-flex;
  align-items: center;
}

.gms-details-btn {
  display: flex;
  justify-content: flex-end;
}

.gms-details-btn .assign-btn {
  background: #004877 !important;
  color: var(--white-color) !important;
}

.gms-details-btn .ant-btn:hover {
  color: var(--white-color);
}

.gms-modal .ant-picker {
  width: -webkit-fill-available;
  height: 48px;
}

.gms-details-img img {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  display: inline-block;
  margin-right: 16px;
}

.view-gms-details .modal-header span {
  border-color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #e67e22;
  padding: 3px 10px;
  background: rgba(230, 126, 34, 0.12);
  border-radius: 21px;
  margin-right: 14px;
}

.marker {
  background-color: red !important;
  height: 10px;
  width: 10px;
}

.tms-tabs-container .client-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  position: absolute;
  width: -webkit-fill-available;
}

/* .page-heading button {
  border-radius: 8px;
  background: var(--polstrat-primary);
  box-shadow: 0px 4px 10px 0px rgba(0, 72, 119, 0.3);
} */


.map-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 15px;
  border-radius: 8px;
  /* background: rgba(59, 190, 232, 0.06); */
  background-color: #fff;
}
.loader {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.map-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 50vh;
  margin: 0 auto;
}
.bold-text {
  font-weight: bold;
}
.gms-uploaded-images-div {
  display: flex;
  flex-wrap: wrap;
  max-width: 75%;
}

.image-box {
  margin: 10px;
}

.uploaded-image_item {
  width: 100px;
  height: 100px;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.uploaded-image_item:hover {
  border: 2px solid #007bff;
}
.task_name_column {
  width: 70%;
  margin-left: 20px;
}

.dropdown-content {
  background-color: var(--light-color);
  border: 1px solid var(--border-line);
  border-radius: 8px 0 8px 8px;
  border-top: 0 solid #cddee9;
  box-shadow: 1px 3px 10px #00487733;
  height: auto;
  left: auto;
  margin-right: 2%;
  padding: 10px;
  position: absolute;
  right: 0;
  width: -webkit-max-content;
  width: max-content;
  z-index: 99;
}

